<template>
    <div class="articles-content">
        <div class="articles-item">
            <div class="articles-name">分类阅读：</div>
            <div class="articles-list">
                <div class="item" :class="{current:currentTopic === articlesIndex}" v-for="(articlesItem,articlesIndex) in articlesClassification" :key="articlesIndex" @click="chooseTopic(articlesIndex,articlesItem)">{{articlesItem}}</div>
            </div>
            <el-input clearable @change="viewList('search')"  prefix-icon="el-icon-search" class="el-int"  placeholder="请输入文字标题" v-model="keyword"></el-input>
            
        </div>
        <div class="articles-info" v-if="articlesList.length > 0">
            <div class="top-animate" v-for="(item,index) in articlesList" :key="index" >
                <a  target="_blank" :href="item.link">
                    <div class="articles-img">
                        <img :src="item.img" alt="" v-show="item.img != null">
                        <img src="../../../assets/img/image/articles.png" alt="" v-show="item.img == null">
                    </div>
                    <div class="info-title">
                        <p class="text">{{item.title}}</p>
                        <p class="time">发布时间：{{item.time}}</p>
                    </div>
                </a>
            </div>
            
        </div>
        <div v-else class="null-img">
            <img src="../../../assets/img/image/null-data.png" alt="">
        </div>
        <div style="text-align: center;padding: 10px 0; font-size: 20px; cursor: pointer;" v-if="isNext" @click="viewList('next')">+点击加载更多</div>
    </div>
</template>

<script>
    import {articleCate,categoryNews} from "../../../utils/apis";
    export default {
        name: "HotArticles",
        data(){
            return{
                isNext:true,
                page:1,
                selectCate:'',
                keyword:'',
                articlesList:[],
                currentTopic:0,
                articlesClassification:[
                    "头条",
                    "新闻",
                    "财经",
                    "体育",
                    "娱乐",
                    "军事",
                    "教育",
                    "科技",
                    "NBA",
                    "股票",
                    "星座",
                    "女性",
                    "健康",
                    "育儿"
                ],
                class_name:'头条'

            }
        },
        mounted() {

            this.categorys().then(res => {
                if (!res.length) {
                    return;
                }
                this.articlesClassification = res;
                this.viewList(this.articlesClassification[0]);
                this.class_name = this.articlesClassification[0];
            })
        },
        methods:{
            categorys() {
                return new Promise((resolve, reject) => {
                    articleCate().then(res => {
                        if (res.code === 200) {
                            resolve(res.data);
                        } else {
                            resolve([]);
                        }
                    }).catch(err => {
                        reject(err);
                    });
                })
                
            },
            // 分类阅读
            chooseTopic(articlesIndex,cate) {
                this.currentTopic = articlesIndex;
                this.selectCate = cate;
                this.viewList('change');
            },
            viewList(type) {
                if(type=='change'||type=='search'){
                    this.page = 1;
                    this.articlesList = [];
                    
                }else if(type == 'next'){
                    this.page++
                }
                let param  ={
                    class_name:this.selectCate,
                    keyword:this.keyword,
                    page:this.page,
                    limit:12
                };
                this.$httpStudent.axiosGetBy(this.$api.hotNewsList, param, res => {
                    if (res.code === 200){
                        this.articlesList = [...this.articlesList,...res.data];
                        if(res.data.length==12&&type!='search'){
                            this.isNext = true;
                        }else{
                            this.isNext = false;
                        }
                    } else {
                        this.$message({
                            type: "error",
                            message: res.msg,
                            duration: 1000
                        });
                        this.isNext = false;
                    }
                });
            }
        }
    }
</script>

<style scoped lang="scss">
    .articles-content{
        display: flex;
        /*width: calc(100% - 280px);*/
        /*padding: 20px 20px 0 0;*/
        flex-direction: column;
        height: 100%;
        .articles-item{
            background: #fff;
            border-radius: 8px;
            display: flex;
            flex-wrap: wrap;
            padding: 20px 20px 10px;
            .el-int{
                width: 250px;
                min-width: 250px;
                margin-left: 150px;
                position: relative;
                top: -5px;
            }
            .articles-name {
                line-height:30px;
            }
            
            
            .articles-list {
                display: flex;
                width: 1%;
                flex: 1;
            
                .item {
                    line-height: 30px;
                    padding: 0 10px;
                    margin: 0 0 10px 10px;
                    color: #666;
                    cursor: pointer;
                    &.current,&:hover{
                        background: #BEC3F1;
                        color: #2338E6;
                        border-radius: 4px;
                    }
                }
            }
        }
        .articles-info{
            margin-right: -20px;
            display: flex;
            flex-wrap: wrap;
            .top-animate{
                width: calc(25% - 20px);
                margin: 20px 20px 0 0;
                border-radius: 8px;
                overflow: hidden;
                .articles-img{
                    width: 100%;
                    height: 0;
                    padding-bottom: 57%;
                    position: relative;
                    overflow: hidden;
                    cursor: pointer;
                    background: #fff;
                    img{
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        max-width: 100%;
                        max-height: 100%;
                    }
                }
                a{
                    color: #333;
                }

                .info-title{
                    background: #fff;
                    padding-bottom: 16px;
                    p{
                        padding:16px 16px 0;
                        margin: 0;
                    }
                    .text{
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display:block;
                        -webkit-box-orient: vertical;
                        max-width: 370px;
                        max-height: 39px;
                        white-space: nowrap;
                    }
                }
            }
        }
        .null-img{
            height:650px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
</style>